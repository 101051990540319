
import {computed, defineComponent, ref} from 'vue'
import store from "@/store";
import {LoadFilterObjects} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import RecentCards from "@/components/base/common/RecentCards.vue";
import Entities from "@/components/base/common/Entities.vue";
import ClaimCard from "@/views/claim/ClaimCard.vue";

export default defineComponent({
  name: "Claims",
  components: {ClaimCard, Entities, RecentCards},
  props: {
    customerId: {},
  },
  setup(props) {
    const filterObject = ref({customerId: props.customerId})
    const page = computed(() => store.state.ClaimModule.listPageClaim)
    return {
      page,
      ...LoadFilterObjects(Actions.LOAD_LIST_CLAIMS, filterObject.value, ['client', 'insured', 'caze', 'examiner'])
    }
  }
})
